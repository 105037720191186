import * as i0 from "@angular/core";
export class LocalService {
    constructor(locale) {
        this.locale = locale;
        this.validUaeComponents = [
            "MENU_TICKETS",
            "MENU_CREATE_TICKETS",
            "MENU_CONFIGURE_TICKETS",
        ];
        this.componentsVisibilityMapping = {
            "en-AE": this.validUaeComponents,
        };
    }
    getLocale() {
        const paths = location.pathname.split("/");
        const urlLocale = paths.length > 1 ? paths[1] : this.locale;
        return urlLocale;
    }
    isLocaleUAE() {
        const locale = this.getLocale();
        return locale === "en-AE";
    }
    isLocaleIndia() {
        const locale = this.getLocale();
        return locale === "en-IN";
    }
    isComponentVisible(component) {
        const locale = this.getLocale();
        // in case en-IN show all modules as its default
        if (locale === "en-IN")
            return true;
        return this.componentsVisibilityMapping[locale].includes(component);
    }
    getTimeZone() {
        return localStorage.getItem("timezone");
    }
    getDate(date = new Date()) {
        try {
            date = new Date(date);
            const options = {
                timeZone: localStorage.getItem("timezone"),
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            };
            const country = localStorage.getItem("country") || 'IN';
            const formatter = new Intl.DateTimeFormat(`en-${country.toUpperCase()}`, options);
            const parts = formatter.formatToParts(date);
            // Extract date and time components
            const year = parts.find((p) => p.type === "year") &&
                parts.find((p) => p.type === "year").value
                ? parts.find((p) => p.type === "year").value
                : "";
            const month = parts.find((p) => p.type === "month") &&
                parts.find((p) => p.type === "month").value
                ? parts.find((p) => p.type === "month").value
                : "";
            const day = parts.find((p) => p.type === "day") &&
                parts.find((p) => p.type === "day").value
                ? parts.find((p) => p.type === "day").value
                : "";
            const hour = parts.find((p) => p.type === "hour") &&
                parts.find((p) => p.type === "hour").value
                ? parts.find((p) => p.type === "hour").value
                : "";
            const minute = parts.find((p) => p.type === "minute") &&
                parts.find((p) => p.type === "minute").value
                ? parts.find((p) => p.type === "minute").value
                : "";
            const second = parts.find((p) => p.type === "second") &&
                parts.find((p) => p.type === "second").value
                ? parts.find((p) => p.type === "second").value
                : "";
            const period = parts.find((p) => p.type === "dayPeriod") &&
                parts.find((p) => p.type === "dayPeriod").value
                ? parts.find((p) => p.type === "dayPeriod").value
                : "";
            let adjustedHour = parseInt(hour, 10);
            if (period === "PM" && adjustedHour < 12) {
                adjustedHour += 12;
            }
            else if (period === "AM" && adjustedHour === 12) {
                adjustedHour = 0;
            }
            // Create a new Date object with these components
            const localDate = new Date(`${year}-${month}-${day}T${String(adjustedHour).padStart(2, '0')}:${minute}:${second}`);
            return localDate;
        }
        catch (error) {
            return new Date();
        }
    }
}
LocalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalService_Factory() { return new LocalService(i0.ɵɵinject(i0.LOCALE_ID)); }, token: LocalService, providedIn: "root" });
